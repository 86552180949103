<template>
  <div class="room-list">
    <section class="table-search">
      <div class="search-L">
        <el-select v-model="tableParams.merchantId" placeholder="请选择商户" @change="onMerchantSel" clearable class="mr20">
          <el-option v-for="item in commercialList" :key="item.merchantId" :label="item.merchantName"
            :value="item.merchantId">
          </el-option>
        </el-select>
        <el-select v-model="tableParams.shopId" placeholder="请选择店铺" clearable @change="onShopSel" class="mr20">
          <el-option v-for="item in tableShopList" :key="item.shopId" :label="item.shopName" :value="item.shopId">
          </el-option>
        </el-select>
        <el-select v-model="tableParams.serviceId" placeholder="请选择服务内容" clearable @change="onServiceSel" class="mr20">
          <el-option v-for="item in tableServiceList" :key="item.serviceId" :label="item.serviceName"
            :value="item.serviceId">
          </el-option>
        </el-select>
      </div>
      <div class="search-R">
        <el-button type="primary" @click="onAdd()">新增位置</el-button>
      </div>
    </section>
    <!-- 表格 -->
    <el-table :data="tableData" border stripe :header-cell-style="{
      background: '#FAFAFA', color: '#000000'
    }">
      <el-table-column label="所属商户" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.merchantName || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="所属店铺" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.shopName || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="所属服务内容" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.serviceName || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="位置名称" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.roomName || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column width="200" label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="text" size="medium" @click.stop="onEdit(scope.row)"
            v-has="'commercial_room_edit'">编辑</el-button>
          <el-button type="text" size="medium" @click.stop="onDel(scope.row)" v-has="'commercial_room_del'">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="table-footer">
      <el-pagination layout="total,sizes, prev, pager, next,jumper" background :total="total"
        :page-size="tableParams.size" @size-change="onSizeChange" :page-sizes="[10, 20, 50, 100]"
        :current-page.sync="currentPage" @current-change="onCurrentPage">
      </el-pagination>
    </div>
    <!-- 编辑弹框 -->
    <el-dialog :visible.sync="editShow" :title="editTitle" :close-on-click-modal="false" width="30%"
      custom-class="add-dialog">
      <el-form :model="params" :rules="rules" ref="form-ref">
        <!-- 商户列表 -->
        <el-form-item label="所属商户" label-width="120px" prop="merchantId">
          <el-select v-model="params.merchantId" placeholder="请选择所属商户" filterable @change="onMerchant()"
            :disabled="editTitle == '编辑位置'">
            <el-option v-for="item in commercialList" :key="item.merchantId" :label="item.merchantName"
              :value="item.merchantId">
            </el-option>
          </el-select>
        </el-form-item>
        <!-- 店铺列表 -->
        <el-form-item label="所属店铺" label-width="120px" prop="shopId">
          <el-select v-model="params.shopId" placeholder="请选择所属店铺" @change="onShop()" :disabled="editTitle == '编辑位置'">
            <el-option v-for="item in shopList" :key="item.shopId" :label="item.shopName" :value="item.shopId">
            </el-option>
          </el-select>
        </el-form-item>
        <!-- 服务内容 -->
        <el-form-item label="服务内容" label-width="120px" prop="serviceId">
          <el-select v-model="params.serviceId" placeholder="请选择所属店铺" @change="onService()"
            :disabled="editTitle == '编辑位置'">
            <el-option v-for="item in serviceList" :key="item.serviceId" :label="item.serviceName"
              :value="item.serviceId">
            </el-option>
          </el-select>
        </el-form-item>
        <!-- 服务名称 -->
        <el-form-item label="位置名称" label-width="120px" prop="roomName">
          <el-input v-model.trim="params.roomName" autocomplete="off" maxlength="16" placeholder="请填写位置名称"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editShow = false">取 消</el-button>
        <el-button type="primary" @click="onSubmit('form-ref')">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getRoomList,
  getRoomEdit,
  getRoomDel,
} from "@/api/commercial/room"
import {
  getAutoCommercialList,
} from "@/api/commercial/commercial"
import {
  getIdShopList,
} from "@/api/commercial/shop"
import {
  getIdServiceList,
} from "@/api/commercial/service"
import {
  ObjToOpt,
} from "@/utils/utils"
import { rules } from "@/db/rules";
import { merchantStatusObj } from "@/db/objs";
export default {
  data() {
    return {
      rules,
      ObjToOpt,
      merchantStatusObj,
      commercialList: [],// 商户列表
      tableShopList: [],//店铺列表
      tableServiceList: [],//服务列表
      // 列表
      currentPage: 1, // 当前页
      total: 0, // 总条数  
      tableData: [],
      tableParams: {
        page: 1,
        size: 10,
        merchantId: "", //商户ID
        shopId: "", //店铺ID
        serviceId: "", //服务ID
      },
      // 表单
      editShow: false, //弹框开关
      editTitle: "", //弹框姓名
      shopList: [],//店铺列表
      serviceList: [],//服务列表
      params: {
        merchantId: "", //商户ID
        shopId: "", //店铺ID
        roomName: "", //服务名称
      },
    };
  },
  mounted() {
    this.getAutoCommercialList();// 【请求】商户下拉列表
  },
  methods: {
    // 【请求】商户下拉列表
    getAutoCommercialList() {
      getAutoCommercialList().then((res) => {
        if (res.isSuccess == "yes") {
          this.commercialList = res.data;
          if (res.data && res.data.length > 0) {
            this.tableParams.merchantId = res.data[0].merchantId;
            this.getTableIdShopList();
          }
        }
      });
    },

    // 【请求】列表
    getRoomList() {
      let data = this.tableParams;
      getRoomList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },

    // 【请求】店铺列表
    getIdShopList() {
      let data = {
        merchantId: this.params.merchantId
      };
      getIdShopList(data).then(res => {
        if (res.isSuccess == "yes") {
          this.shopList = res.data;
        }
      })
    },

    // 【请求】服务列表
    getIdServiceList() {
      let data = {
        shopId: this.params.shopId
      };
      getIdServiceList(data).then(res => {
        if (res.isSuccess == "yes") {
          this.serviceList = res.data;
        }
      })
    },


    // 【请求】新增编辑
    getRoomEdit() {
      let data = this.params;
      getRoomEdit(data).then(res => {
        if (res.isSuccess == "yes") {
          this.editShow = false;
          if (data.roomId) {
            this.getRoomList();
          } else {
            this.reload()
          }
        }
      })
    },

    // 【请求】筛选商户店铺列表
    getTableIdShopList() {
      let data = {
        merchantId: this.tableParams.merchantId
      };
      getIdShopList(data).then(res => {
        if (res.isSuccess == "yes") {
          this.tableShopList = res.data;
          this.tableParams.shopId = res.data[0].shopId;
          this.getRoomList();// 【请求】列表
          this.getTableIdServiceList();
        }
      })
    },

    // 【请求】筛选服务内容列表
    getTableIdServiceList() {
      let data = {
        shopId: this.tableParams.shopId
      };
      getIdServiceList(data).then(res => {
        if (res.isSuccess == "yes") {
          this.tableServiceList = res.data;
        }
      })
    },

    // 【监听】筛选商户
    onMerchantSel() {
      this.tableParams.shopId = "";
      this.tableParams.serviceId = "";
      this.reload();
      if (this.tableParams.merchantId) {
        this.getTableIdShopList();
      } else {
        this.tableShopList = [];
      }
    },

    // 【监听】筛选店铺
    onShopSel() {
      this.$forceUpdate();
      this.tableParams.serviceId = "";
      this.reload();
      if (this.tableParams.shopId) {
        this.getTableIdServiceList();
      } else {
        this.tableServiceList = [];
      }
    },

    // 【监听】服务筛选
    onServiceSel() {
      this.$forceUpdate();
      this.reload();
    },

    // 【监听】表格重载
    reload() {
      this.tableParams.page = 1;
      this.currentPage = 1;
      this.getRoomList();
    },

    // 【监听】表格分页点击
    onCurrentPage(page) {
      this.tableParams.page = page;
      this.getRoomList();
    },

    // 【监听】表格条数点击
    onSizeChange(size) {
      this.tableParams.size = size;
      this.reload();
    },

    // 【监听】弹框商户选择
    onMerchant() {
      this.getIdShopList();
      this.params.shopId = "";
    },

    // 【监听】弹框店铺选择
    onShop() {
      this.$forceUpdate();
      this.getIdServiceList();
    },

    // 【监听】服务选择
    onService() {
      this.$forceUpdate();
    },

    // 【监听】新增
    onAdd() {
      this.editShow = true;
      this.editTitle = "新增位置";
      this.params = {
        merchantId: "", //商户ID
        shopId: "", //店铺ID
        roomName: "", //服务名称
      }
      this.$nextTick(() => {
        this.$refs["form-ref"].clearValidate();
      });
    },

    // 【监听】编辑
    onEdit(row) {
      this.editShow = true;
      this.editTitle = "编辑位置";
      this.params = JSON.parse(JSON.stringify(row));
      this.getIdShopList();
      this.getIdServiceList();
      this.$nextTick(() => {
        this.$refs["form-ref"].clearValidate();
      });
    },


    // 【监听】表单提交
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.getRoomEdit();
        } else {
          return false;
        }
      });
    },

    // 【监听】删除
    onDel(row) {
      let data = {
        roomId: row.roomId
      }
      this.$confirm('你确定要删除该位置吗?', '删除位置', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      }).then(() => {
        getRoomDel(data).then(res => {
          if (res.isSuccess == "yes") {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.getRoomList();
          }
        })
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.room-list {
  padding: .15rem;
  box-sizing: border-box;
}

.table-search {
  display: flex;
  justify-content: space-between;
  margin-bottom: .15rem;
}

.search-L {
  display: flex;
}

.table-footer {
  margin-top: .1rem;
  display: flex;
  justify-content: flex-end;
}
</style>