import request from '@/utils/request'
// 列表
export function getServiceList(data) {
  return request('post', '/aps/content/contentList', data)
}

// 新增修改
export function getServiceEdit(data) {
  return request('post', '/aps/content/createOrUpdate', data)
}

// 删除
export function getServiceDel(data) {
  return request('post', '/aps/content/contentDel', data)
}

// 列表（通过门店id）
export function getIdServiceList(data) {
  return request('post', '/aps/content/findAll', data)
}