import request from '@/utils/request'
// 列表
export function getRoomList(data) {
  return request('post', '/aps/room/list', data)
}

// 编辑
export function getRoomEdit(data) {
  return request('post', '/aps/room/createOrUpdate', data)
}

// 删除
export function getRoomDel(data) {
  return request('post', '/aps/room/delete', data)
}